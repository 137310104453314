// Modal.js
import React from 'react'
import styles from './styles.module.css'

const InfoModal = ({ show, onClose, children }: any) => {
  if (!show) {
    return null
  }

  const handleOverlayClick = (event: any) => {
    if (event.target === event.currentTarget) {
      onClose()
    }
  }

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={`${styles.modalContent}`}>
        {children}
      </div>
    </div>
  )
}

export default InfoModal
