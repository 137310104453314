import OafToken from '@components/molecules/OafToken'
import OafTokenMobile from '@components/molecules/OafTokenMobile'
import { useIsMobile } from '@hooks/usIsMobile'

export default function Token () {
  const isMobile = useIsMobile()

  return (
    <section>
      {
        isMobile
          ? (
            <>
              <OafTokenMobile />
            </>
          )
          : <>
            <OafToken />
          </>
      }
    </section>
  )
}
