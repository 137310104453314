import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import styles from './styles.module.css'
import { useIsMobile } from '@hooks/usIsMobile'
import EcosystemElementsMobile from '@components/molecules/EcosystemElementsMobile'
import EcosystemElements from '@components/molecules/EcosystemElements'
import { useIsTablet } from '@hooks/useIsTablet'

export default function Ecosystem () {
  const t = useTranslations('ecosystem')
  const lang = useLocale()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  return (
    <section className='flex flex-col items-center mt-[152px] md:mt-60'>
      <div className='mb-20 md:mb-[121px] xl:mb-[136px] mr-24'>
        <h1>{t('discover')}</h1>
        <div className='flex items-end w-fit relative'>
          <h1>{t('our')}</h1>
          <div className={`absolute bottom-[20%] md:bottom-[30%] ${styles.alignLeft}`}>
            <div className='relative w-fit'>
              <Image src='/img/ecosystem-title-new.webp' width={415} height={272} alt='Discover our ecosystem' className={`${lang === 'en' ? '' : 'md:!w-[430px] md:!max-w-[430px]'}`} />
              <p className='font-londrina text-secondary text-[25px] md:text-[60px] whitespace-nowrap pl-6 pr-12 md:pl-12 md:pr-24 -mt-[70px] md:-mt-[130px] uppercase' style={{ transform: 'rotate(-4.65deg)' }}>{t('ecosystem')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center'>
        {
          isMobile || isTablet ? <EcosystemElementsMobile /> : <EcosystemElements />
        }
      </div>
    </section>
  )
}
