import config from 'config/all-config.json'
import Image from 'next/image'
import { useState } from 'react'
import 'swiper/css'
import 'swiper/css/free-mode'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslations } from 'next-intl'
import FlipCard from '@components/atoms/FlipCard'

export default function TeamSliderMobile () {
  const t = useTranslations('team')
  const [stageSliderInstance, setStageSliderInstance] = useState<any>(null)
  const [shouldFlip, setShouldFlip] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)

  const handleFlip = () => {
    setShouldFlip(!shouldFlip)
  }

  return (
    <div className='flex flex-col items-center w-full max-w-[1050px]'>
      <div className='flex flex-col justify-between h-full'>
        <div className='flex w-full overflow-hidden'>
          <Swiper
            slidesPerView={1}
            loop={true}
            grabCursor={true}
            spaceBetween={0}
            modules={[FreeMode]}
            initialSlide={0}
            className='!w-fit max-w-[90vw]'
            onSlideChange={(index) => { setActiveIndex(index.realIndex); setShouldFlip(false) }}
            onInit={(ev) => {
              setStageSliderInstance(ev)
            }}
          >
            {
              config.team.cards.map(({ title, img, color }, index) => {
                return (
                  <SwiperSlide key={title} className='!w-[90vw] !flex !justify-center'>
                    <FlipCard img={img} color={color} activeIndex={activeIndex} index={index} flippedCard={shouldFlip} />
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </div>
      </div>
      <div className='mt-[50px] flex justify-between w-full'>
        <button onClick={() => stageSliderInstance?.slidePrev()}>
          <Image src='/img/arrow-left.svg' width={62} height={20} alt='Choose another team card' />
        </button>

        <button onClick={() => handleFlip()} className='relative w-fit h-fit pt-4 pb-4'>
          <p className='py-2 px-6 text-orange text-[24px] font-londrina font-normal z-10 relative uppercase'>
            {t('flip')}
          </p>
          <Image src='/img/download-border.svg' fill alt='flip card in order to discover our team member story' className='!w-full !h-full !max-h-full' />
        </button>

        <button onClick={() => stageSliderInstance?.slideNext()}>
          <Image src='/img/arrow-right.svg' width={62} height={20} alt='Choose another team card' />
        </button>
      </div>
    </div>
  )
}
