import config from 'config/all-config.json'
import Image from 'next/image'
import { useRef, useState } from 'react'
import styles from './styles.module.css'
import TeamCard from '@components/atoms/TeamCardDescription'
import 'swiper/css'
import 'swiper/css/free-mode'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

export default function TeamSlider () {
  const [selectedItem, setSelectedItem] = useState(0)
  const ref = useRef<any>(null)
  const imageRef = useRef<any>(null)
  const [stageSliderInstance, setStageSliderInstance] = useState<any>(null)
  const [disabled, setDisabled] = useState(false)

  const handleSelect = (index: number) => {
    setDisabled(true)
    setTimeout(() => {
      setDisabled(false)
    }, 500)

    setTimeout(() => {
      index < 0 ? stageSliderInstance?.slidePrev() : stageSliderInstance?.slideNext()
      ref.current.classList.add(styles.disappear)
      imageRef.current.classList.add(styles.disappear)
      setTimeout(() => {
        let temp
        if (selectedItem === 0 && index === -1) {
          temp = 8
        } else if (selectedItem === 8 && index === 1) {
          temp = 0
        } else temp = selectedItem + index
        setSelectedItem(temp)
      }, 200)
      setTimeout(() => {
        ref.current.classList.remove(styles.disappear)
        imageRef.current.classList.remove(styles.disappear)
      }, 400)
    }, 200)
  }

  const handleSelectOnCard = (index: number) => {
    if (Math.abs(selectedItem - index) === 2 || (selectedItem === 8 && index === 1) || (selectedItem === 7 && index === 0)) {
      stageSliderInstance?.slideNext()
      setTimeout(() => {
        stageSliderInstance?.slideNext()
      }, 400)
    } else stageSliderInstance?.slideNext()

    ref.current.classList.add(styles.disappear)
    imageRef.current.classList.add(styles.disappear)
    setTimeout(() => {
      setSelectedItem(index)
    }, 200)
    setTimeout(() => {
      ref.current.classList.remove(styles.disappear)
      imageRef.current.classList.remove(styles.disappear)
    }, 400)
  }

  return (
    <div className='flex flex-col items-center w-full max-w-[1050px]'>
      <div className='grid grid-cols-2 gap-x-12 w-full'>
        <div ref={imageRef} className='relative !h-[656px]'>
          <Image src={`/img/team/${config.team.cards[selectedItem].img}.webp`} fill alt={config.team.cards[selectedItem].title} className='min-h-full min-w-full' />
        </div>

        <div className='flex flex-col justify-between h-full'>
          <div ref={ref}>
            <TeamCard selectedItem={selectedItem} />
          </div>
          <div className='flex w-full overflow-hidden relative'>
            <Swiper
              slidesPerView={'auto'}
              loop={true}
              grabCursor={true}
              speed={300}
              spaceBetween={0}
              modules={[FreeMode]}
              initialSlide={1}
              className='w-fit'
              onInit={(ev) => {
                setStageSliderInstance(ev)
              }}
            >
              {
                config.team.cards.map(({ title, img }, index) => {
                  if (index !== selectedItem) {
                    return (
                      <SwiperSlide key={title} className='!w-fit'>
                        <div onClick={() => handleSelectOnCard(index)} className='mr-2 cursor-pointer w-fit'>
                          <Image loading='lazy' src={`/img/team/${img}.webp`} width={164} height={233} alt='Team member' className='min-w-[164px] min-h-[233px] w-[164px] h-[233px]' />
                        </div>
                      </SwiperSlide>
                    )
                  } else {
                    return (
                      <SwiperSlide key={title} className='!w-fit'>
                        <div onClick={() => handleSelectOnCard(index)} className='mr-2 cursor-pointer w-fit'>
                          <Image loading='lazy' src={`/img/team/${img}.webp`} width={164} height={233} alt='Team member' className='min-w-[164px] min-h-[233px] w-[164px] h-[233px]' />
                        </div>
                      </SwiperSlide>
                    )
                  }
                })
              }
            </Swiper>
            <div className='absolute bottom-0 right-0 w-[124px] h-[300px] z-10' style={{ background: 'linear-gradient(270deg, #0A1F28 0%, rgba(10, 31, 40, 0) 100%)' }}></div>
          </div>
        </div>
      </div>
      <div className='mt-[50px] flex justify-between w-full'>
        <button disabled={disabled} onClick={() => handleSelect(-1) }>
          <Image src='/img/arrow-left.svg' width={100} height={32} alt='Choose another team card' />
        </button>

        <button disabled={disabled} onClick={() => handleSelect(1)}>
          <Image src='/img/arrow-right.svg' width={100} height={32} alt='Choose another team card' />
        </button>
      </div>
    </div>
  )
}
