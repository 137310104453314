import config from 'config/all-config.json'
import { useLocale, useTranslations } from 'next-intl'
import { useState } from 'react'
import styles from './styles.module.css'
import Image from 'next/image'

export default function WhoTheyAre () {
  const t = useTranslations('who')
  const lang = useLocale()
  const [selectedSection, setSelectedSection] = useState(0)
  const [displayAnimation, setDisplayAnimation] = useState(false)

  const handleChange = (index: number) => {
    setSelectedSection(index)
    setDisplayAnimation(true)
    setTimeout(() => {
      setDisplayAnimation(false)
    }, 500)
  }

  return (
    <section>
      <div className='mb-[147px] ml-12'>
        <h1>{t('who')}</h1>
        <div className='flex items-end w-fit relative'>
          <h1>{t('the')}</h1>
          <div className='absolute bottom-[50%]' style={{ left: 'calc(100% + 48px)' }}>
            <div className='relative w-fit'>
              <Image src='/img/who-title.svg' width={550} height={200} alt='Who are the happy marmots' />
              <p className='font-londrina text-secondary text-[52px] xl:text-[60px] whitespace-nowrap pl-10 xl:pl-12 pr-20 xl:pr-24 -mt-[90px] xl:-mt-[104px] uppercase' style={{ transform: 'rotate(-4.65deg)' }}>{t('happy')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`grid gap-[88px] ${styles.columns} mb-40`}>
        <div>
          {
            config.who.map(({ title }, index) => {
              return (
                <button
                  key={title}
                  onClick={() => handleChange(index)}
                  className='flex items-center'
                >
                  <Image src={selectedSection === index ? '/img/mark.svg' : '/img/mark-empty.svg'} width={38} height={50} alt='' />
                  <p className={`flex items-center ml-6 ${selectedSection === index ? 'font-londrina text-orange' : 'font-londrina-outline text-orange'} text-[64px] xl:text-[80px] leading-[100px]`}>{t(`${index}.title`)}</p>
                </button>
              )
            })
          }
        </div>
        <div className='w-full'>
          <p className={`text-white font-medium text-[24px] leading-[48px] text-start w-full ${lang === 'en' ? 'h-[500px]' : 'h-[600px]'} lg:h-[400px] ${displayAnimation && styles.displayAnswer}`}>{t(`${selectedSection}.description`)}</p>
        </div>
      </div>
    </section>
  )
}
