import OutlineButton from '@components/atoms/OutlineButton'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

export default function HikeWithUs () {
  const t = useTranslations('hike')

  return (
    <section className='flex flex-col items-center mt-[152px] md:mt-60'>
      <div className='h-fit relative w-full max-w-[1032px]'>
        <div className='px-[40px] lg:px-[70px] grid gap-2 lg:gap-10 relative z-10 w-full xl:mb-10' style={{ gridTemplateColumns: '3fr 2fr' }}>
          <div className='pl-2 pt-24 pb-16 flex flex-col justify-center'>
            <h3>{t('title')}</h3>
            <p className='text-[24px] mt-4 leading-[48px] font-medium'>{t('description')}</p>
            <div className='flex w-full gap-4 lg:gap-6'>
              <OutlineButton text={t('ios')} link='https://testflight.apple.com/join/hto9bels' className='xl:!text-[32px] !px-6 lg:!px-8' />
              <OutlineButton text={t('android')} link='https://play.google.com/apps/testing/com.outdoor.aficionados' longerButton={true} className='xl:!text-[32px] !px-6 lg:!px-8' />
            </div>
          </div>
          <div className='relative -translate-y-[12%] flex justify-center'>
            <Image src='/img/phone.svg' width={272} height={456} alt='Mobile app' className='md:!w-[208px] md:!h-[350px] xl:w-[272px] xl:h-[456px]' />
          </div>
        </div>
        <Image src={window.innerWidth >= 450 && window.innerWidth <= 769 ? '/img/card-tablet.svg' : '/img/card-bg.svg'} fill alt='Hike with us' />
      </div>
    </section>
  )
}
