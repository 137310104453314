import Image from 'next/image'

export default function OutlineButton ({ text, link, longerButton, className, borderClass } : {text: string, link: string, longerButton?: boolean, className?: string, borderClass?: string}) {
  return (
    <div className='relative w-fit h-fit pt-4 pb-4'>
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
        className={`py-2 block w-full md:py-4 px-6 md:px-8 text-orange text-[24px] xl:text-[32px] font-londrina font-normal z-10 relative ${className}`}
      >
        {text}
      </a>
      <Image src={longerButton ? '/img/buttons-bg.svg' : '/img/buttons-border.svg'} fill alt='Hike with us' className={`!w-full !h-full !max-h-full ${borderClass}`} />
    </div>
  )
}
