import Card1 from '@components/atoms/cards/Card1'
import Card3 from '@components/atoms/cards/Card3'
import Card4 from '@components/atoms/cards/Card4'
import config from 'config/all-config.json'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { Fragment, useRef, useState } from 'react'

export default function Nft () {
  const t = useTranslations('nft')
  const [showMore, setShowMore] = useState(false)
  const ref = useRef<any>()
  const cardsRef = useRef<any>()
  const cards = [
    {
      component: <Card1 fill={'#C6EE3A'} />
    },
    {
      component: <Card4 fill={'#FF902F'} />
    },
    {
      component: <Card3 fill={'#6BD5FF'} />
    },
    {
      component: <Card4 fill={'#FF902F'} />
    },
    {
      component: <Card1 fill={'#6BD5FF'} />
    }
  ]

  const handleShow = () => {
    if (showMore) {
      ref.current.classList.add('disappear')
      cardsRef.current.scrollIntoView()
      setTimeout(() => {
        ref.current.classList.remove('disappear')
        setShowMore(false)
      }, 300)
    } else setShowMore(true)
  }

  return (
    <section className='flex flex-col items-center w-fit'>
      <div className='mb-[137px] pl-24 w-full'>
        <h1>{t('moreThan')}</h1>
        <div className='flex items-end w-fit relative'>
          <h1>{t('just')}</h1>
          <div
            className='absolute bottom-[40%]'
            style={{ left: 'calc(100% + 48px)' }}
          >
            <div className='relative w-fit'>
              <Image
                src='/img/nft-title-desktop-new.webp'
                width={270}
                height={250}
                alt='More than just an Nft'
              />
              <p
                className='font-londrina text-secondary text-[80px] whitespace-nowrap pl-12 pr-24 -mt-[134px] uppercase'
                style={{ transform: 'rotate(-4.65deg)' }}
              >
                {t('nft')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center w-fit'>
        <div ref={cardsRef} className='grid grid-cols-3 gap-y-2 gap-x-1 w-fit'>
          {config.nft.cards.map((nft, index: number) => {
            return (
              <Fragment key={index}>
                {index < 3 && (
                  <div className='h-[436px] w-fit relative'>
                    <div className='w-[319px] h-[436px]'>{cards[index].component}</div>
                    <div className='absolute w-full h-full top-0 left-0 z-10'>
                      <div className='pt-[63px] px-[25px]'>
                        <h5 className='text-primary'>
                          {t(`cards.${index}.title`)}
                        </h5>
                        <p className='text-primary text-[24px] leading-[48px] font-londrina mb-4 font-light'>
                          {t(`cards.${index}.subtitle`)}
                        </p>
                        <p className='text-primary text-[16px] leading-[32px] font-medium'>
                          {t(`cards.${index}.description`)}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )
          })}
        </div>
        {showMore && (
          <div
            ref={ref}
            className='grid grid-cols-3 gap-y-2 gap-x-1 w-fit mt-2 show-from-above'
          >
            {config.nft.cards.map((nft, index: number) => {
              return (
                <Fragment key={index}>
                  {index >= 3 && (
                    <div className='w-fit h-[436px] relative'>
                      <div className='w-[319px] h-[436px]'>{cards[index - 2].component}</div>
                      <div className='absolute w-full h-full top-0 left-0 z-10'>
                        <div className='pt-[63px] px-[25px]'>
                          <h5 className='text-primary'>
                            {t(`cards.${index}.title`)}
                          </h5>
                          <p className='text-primary text-[24px] leading-[48px] font-londrina mb-4 font-light'>
                            {t(`cards.${index}.subtitle`)}
                          </p>
                          <p className='text-primary text-[16px] leading-[32px] font-medium'>
                            {t(`cards.${index}.description`)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              )
            })}
          </div>
        )}
      </div>
      <div className='flex justify-center mt-10'>
        <button
          onClick={() => handleShow()}
          className='text-orange uppercase text-[32px] leading-50 font-londrina'
        >
          {showMore ? t('less') : t('more')}
        </button>
      </div>
    </section>
  )
}
