import { useTranslations } from 'next-intl'
import Image from 'next/image'
import styles from './styles.module.css'
import WhitepaperCard from '@components/molecules/WhitepaperCard'
import { useIsMobile } from '@hooks/usIsMobile'
import WhitepaperCardMobile from '@components/molecules/WhitepaperCardMobile'

export default function Whitepaper () {
  const t = useTranslations('whitepaper')
  const isMobile = useIsMobile()

  return (
    <section className='flex flex-col items-center mt-[152px] md:mt-60'>
      <div className='mb-20 md:mb-[136px] mr-12 md:mr-24'>
        <h1>{t('diveInto')}</h1>
        <div className='flex items-end w-fit relative'>
          <h1>{t('our')}</h1>
          <div className={`absolute bottom-[20%] md:bottom-[30%] ${styles.alignLeft}`}>
            <div className='relative w-fit'>
              <Image src='/img/whitepaper-title.svg' width={550} height={200} alt='Who are the happy marmots' />
              <p className='font-londrina text-secondary text-[25px] md:text-[60px] whitespace-nowrap pl-6 pr-12 md:pl-12 md:pr-24 -mt-[50px] md:-mt-[90px] uppercase' style={{ transform: 'rotate(-2deg)' }}>{t('whitepaper')}</p>
            </div>
          </div>
        </div>
      </div>
      {
        isMobile ? <WhitepaperCardMobile /> : <WhitepaperCard />
      }
    </section>
  )
}
