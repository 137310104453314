import OutlineButton from '@components/atoms/OutlineButton'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import styles from './styles.module.css'

export default function HikeWithUsMobile () {
  const t = useTranslations('hike')

  return (
    <section className='flex flex-col items-center mt-[240px]'>
      <div className='h-fit relative w-full'>
        <div className='px-[36px] gap-6 grid grid-cols-1 relative z-10 w-full mb-[67px]'>
          <div className={`relative -mt-[60%] ${styles.customMargin} flex justify-center`}>
            <Image src='/img/phone.svg' width={160} height={269} alt='Mobile app' />
          </div>
          <div className='flex flex-col justify-center items-center mt-6'>
            <div className='text-center px-3'>
              <h2>{t('title')}</h2>
              <p className='text-[16px] mt-2 leading-[32px] font-medium'>{t('description')}</p>
            </div>
            <div className='mt-8 flex justify-center'>
              <div className='relative w-fit h-fit'>
                <div className='flex flex-col justify-center items-center w-full'>
                  <div className='relative w-fit h-fit'>
                    <a
                      href={'https://testflight.apple.com/join/hto9bels'}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='py-2 block w-full md:py-4 md:px-8 text-orange text-[24px] xl:text-[32px] font-londrina font-normal z-10 relative !px-8 xl:md:py-0'
                    >
                      {t('ios')}
                    </a>
                    <Image src={'/img/buttons-border.svg'} fill alt='Hike with us' className='!w-full !h-full !max-h-full' />
                  </div>
                  <OutlineButton text={t('android')} link='https://play.google.com/apps/testing/com.outdoor.aficionados' longerButton={true} className='xl:!text-[32px] !px-8' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Image src='/img/hike-with-us-mobile-card.svg' fill alt='Hike with us' />
      </div>
    </section>
  )
}
