'use client'
import Image from 'next/image'
import config from 'config/all-config.json'
import { useEffect, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'
import styles from './styles.module.css'

export default function EcosystemElements () {
  const t = useTranslations('ecosystem.cards')
  const [selectedItem, setSelectedItem] = useState(0)
  const [hoveredElement, setHoveredElement] = useState(-1)
  const [clicked, setClicked] = useState(false)
  const ref = useRef<any>()

  const position = [
    {
      top: -10,
      left: 53
    },
    {
      top: 23,
      left: 93
    },
    {
      top: 77,
      left: 87
    },
    {
      top: 93,
      left: 38
    },
    {
      top: 59,
      left: -16
    },
    {
      top: 6,
      left: -9
    }
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      if (clicked) return () => clearInterval(interval)

      ref.current.classList.add(styles.disappear)
      setTimeout(() => {
        selectedItem < 5 ? setSelectedItem((prev) => prev + 1) : setSelectedItem(0)
      }, 200)
      setTimeout(() => {
        ref.current.classList.remove(styles.disappear)
      }, 400)
    }, 4000)
    return () => clearInterval(interval)
  }, [selectedItem])

  const handleSelect = (index: number) => {
    setClicked(true)
    ref.current.classList.add(styles.disappear)
    setTimeout(() => {
      setSelectedItem(index)
    }, 200)
    setTimeout(() => {
      ref.current.classList.remove(styles.disappear)
    }, 400)
  }

  return (
    <section className='max-w-[750px] h-[750px] w-full relative mt-[80px]'>
      <Image src='/img/circle.svg' fill alt='Discover our ecosystem' />
      {
        config.ecosystem.cards.map(({ img, hover }, index) => {
          return (
            <div
              key={index}
              onClick={() => handleSelect(index)}
              className={`absolute ${selectedItem === index ? styles.zoom : ''}`} style={{ top: `${position[index].top}%`, left: `${position[index].left}%` }}
            >
              <Image
                onMouseEnter={() => setHoveredElement(index)}
                src={`/img/ecosystem/${img}`}
                width={145}
                height={145}
                alt={t(`${index}.title`)}
                className='z-10 relative min-w-[145px] min-h-[145px]'
              />
              <Image
                onMouseLeave={() => setHoveredElement(-1)}
                src={`/img/ecosystem/${hover}`}
                width={145}
                height={145}
                alt={t(`${index}.title`)}
                className={`absolute top-0 left-0 min-w-[145px] min-h-[145px] ${
                  hoveredElement === index || selectedItem === index ? 'z-20 ' : 'z-0'
                }`}
              />
            </div>
          )
        })
      }
      <div ref={ref} className='absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-start'>
        <h4 className='mb-6'>{t(`${selectedItem}.title`)}</h4>
        <p className='text-[24px] leading-[48px] font-medium'>{t(`${selectedItem}.description`)}</p>
      </div>
    </section>
  )
}
