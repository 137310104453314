import Image from 'next/image'
import { useEffect, useState } from 'react'
import ReactCardFlip from 'react-card-flip'
import TeamCard from '../cards/TeamCard'
import TeamCardDescription from '../TeamCardDescription'

export default function FlipCard ({ img, color, activeIndex, index, flippedCard }: {img: string, color: string, activeIndex: number, index: number, flippedCard: boolean}) {
  const [isFlipped, setIsFlipped] = useState(false)

  useEffect(() => {
    activeIndex === index && setIsFlipped(flippedCard)
  }, [flippedCard])

  useEffect(() => {
    setIsFlipped(false)
  }, [activeIndex])

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection={'horizontal'}>
      <div className='w-fit flex justify-center'>
        <Image loading='lazy' src={`/img/team/${img}.webp`} width={328} height={466} alt='Team member' className='min-w-[164px] min-h-[233px] w-full h-[466px] md:w-[462px] md:h-[656px]' />
      </div>

      <div className='relative'>
        <div className='absolute w-full h-full z-10 py-10 px-[31px]'>
          <TeamCardDescription selectedItem={index} isMobile={true} />
        </div>
        <TeamCard color={color} />
      </div>
    </ReactCardFlip>
  )
}
