import Card1 from '@components/atoms/cards/Card1'
import Card4 from '@components/atoms/cards/Card4'
import config from 'config/all-config.json'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { Fragment, useState } from 'react'
import 'swiper/css'
import 'swiper/css/free-mode'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

export default function NftMobile () {
  const t = useTranslations('nft')
  const [activeStage, setActiveStage] = useState(1)
  const [stageSliderInstance, setStageSliderInstance] = useState<any>(null)
  const cards = [
    {
      component: <Card1 fill={'#C6EE3A'} />
    },
    {
      component: <Card4 fill={'#FF902F'} />
    },
    {
      component: <Card1 fill={'#6BD5FF'} />
    },
    {
      component: <Card4 fill={'#C6EE3A'} />
    },
    {
      component: <Card1 fill={'#FF902F'} />
    },
    {
      component: <Card4 fill={'#6BD5FF'} />
    },
    {
      component: <Card1 fill={'#C6EE3A'} />
    },
    {
      component: <Card4 fill={'#FF902F'} />
    },
    {
      component: <Card1 fill={'#6BD5FF'} />
    },
    {
      component: <Card4 fill={'#C6EE3A'} />
    },
    {
      component: <Card1 fill={'#FF902F'} />
    },
    {
      component: <Card1 fill={'#6BD5FF'} />
    },
    {
      component: <Card1 fill={'#C6EE3A'} />
    },
    {
      component: <Card1 fill={'#0a1f28'} />
    }
  ]

  return (
    <section className='flex flex-col items-center w-full'>
      <div className='mb-[80px] w-fit'>
        <h1>{t('moreMobile')}</h1>
        <h1>{t('thanJust')}</h1>
        <div className='flex items-end w-fit relative'>
          <h1>{t('an')}</h1>
          <div
            className='absolute bottom-[20%]'
            style={{ left: 'calc(100% + 20px)' }}
          >
            <div className='relative w-fit'>
              <Image
                src='/img/nft-title-mobile.webp'
                width={270}
                height={250}
                alt='More than just an Nft'
              />
              <p
                className='font-londrina text-secondary text-[42px] whitespace-nowrap pl-6 pr-12 -mt-[75px] uppercase'
                style={{ transform: 'rotate(-4.65deg)' }}
              >
                {t('nft')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        slidesPerView={1}
        grabCursor={true}
        spaceBetween={0}
        modules={[FreeMode]}
        initialSlide={0}
        className='w-fit max-w-[90vw]'
        onSlideChange={(index) => setActiveStage(index.realIndex + 1)}
        onInit={(ev) => {
          setStageSliderInstance(ev)
        }}
      >
        {config.nft.cards.map((_, index) => {
          if (index % 2 === 0) {
            return (
              <SwiperSlide key={index} className='!w-full !h-fit pl-2'>
                <div className='flex flex-col w-full items-center'>
                  <div className='w-[328px] relative'>
                    <div className='w-[328px] h-auto'>{cards[index].component}</div>
                    <div className='absolute w-full h-full top-0 left-0 z-10'>
                      <div className='pt-[59px] px-[28px]'>
                        <h3 className='text-primary'>
                          {t(`cards.${index}.title`)}
                        </h3>
                        <p className='text-primary text-[24px] leading-[48px] font-londrina mb-4 font-light'>
                          {t(`cards.${index}.subtitle`)}
                        </p>
                        <p className='text-primary text-[16px] leading-[32px] font-medium'>
                          {t(`cards.${index}.description`)}
                        </p>
                      </div>
                    </div>
                  </div>
                  {
                    index < 5 &&
                    <div className='w-[328px] relative'>
                      <div className='w-[328px] h-auto'>{cards[index + 1].component}</div>
                      <div className='absolute w-full h-full top-0 left-0 z-10'>
                        <div className='pt-[59px] px-[28px]'>
                          <h3 className='text-primary'>
                            {t(`cards.${index + 1}.title`)}
                          </h3>
                          <p className='text-primary text-[24px] leading-[48px] font-londrina mb-4 font-light'>
                            {t(`cards.${index + 1}.subtitle`)}
                          </p>
                          <p className='text-primary text-[16px] leading-[32px] font-medium'>
                            {t(`cards.${index + 1}.description`)}
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </SwiperSlide>
            )
          } else return <Fragment key={index}></Fragment>
        })}
      </Swiper>

      <div className='flex w-full justify-between items-center mt-4 px-4'>
        <div className='w-[62px]'>
          {stageSliderInstance !== null && activeStage > 1 && (
            <Image
              src='/img/arrow-left.svg'
              alt='Swipe left'
              width={62}
              height={20}
              onClick={() => {
                stageSliderInstance?.slidePrev(); setActiveStage(activeStage - 1)
              }}
            />
          )}
        </div>
        <p className='text-[24px] leading-[48px] text-orange font-londrina'>{activeStage} / 4</p>
        <div className='w-[62px]'>
          {stageSliderInstance !== null && activeStage < 7 && (
            <Image
              src='/img/arrow-right.svg'
              alt='Swipe right'
              width={62}
              height={20}
              onClick={() => {
                stageSliderInstance?.slideNext(); setActiveStage(activeStage + 1)
              }}
            />
          )}
        </div>
      </div>
    </section>
  )
}
