import TeamSliderMobile from '@components/molecules/TeamSliderMobile'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

export default function TeamMobile () {
  const t = useTranslations('team')

  return (
    <section className='flex flex-col items-center mt-[152px]'>
      <div className='flex flex-col items-center max-w-[532px]'>
        <div className='mb-20 mr-24'>
          <h1>{t('all')}</h1>
          <div className='flex items-end w-fit relative'>
            <h1>{t('the')}</h1>
            <div className='absolute bottom-[20%] xl:bottom-[30%]' style={{ left: 'calc(100% + 20px)' }}>
              <div className='relative w-fit'>
                <Image src='/img/team-title.webp' width={415} height={272} alt='Discover our team' />
                <p className='font-londrina text-secondary text-[36px] xl:text-[85px] whitespace-nowrap pl-5 pr-16 xl:pl-10 xl:pr-20 -mt-[55px] xl:-mt-[140px] uppercase' style={{ transform: 'rotate(-4.65deg)' }}>{t('team')}</p>
              </div>
            </div>
          </div>
        </div>

        <TeamSliderMobile />
      </div>
    </section>
  )
}
