import OutlineButton from '@components/atoms/OutlineButton'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'

export default function Footer () {
  const t = useTranslations('footer')
  const lang = useLocale()

  return (
    <section className='w-full flex flex-col items-start ml-[13px] md:ml-0'>
      <div>
        <Image priority src='/img/happy_marmots_logo.webp' width={476} height={200} quality={100} alt='Happy marmots' className='!w-[476px]' />
        <div className='grid grid-cols-1 md:grid-cols-2 mt-6'>
          <div className='text-white font-medium text-[16px] md:text-[24px] leading-[32px] md:leading-[48px]'>{t('description')}</div>
        </div>
      </div>
      <div className='mt-20 mb-16 flex md:gap-[18px] flex-col lg:flex-row items-start'>
        <OutlineButton text={t('terms')} link='/terms-and-conditions' longerButton={lang === 'en'} className='xl:!text-[16px] !px-8' />
        <OutlineButton text={t('cookie')} link='/cookie-policy' className='xl:!text-[16px] !px-8' />
        <div className={`${lang === 'fr' ? 'mt-2 md:mt-0' : ''}`}>
          <OutlineButton text={t('privacy')} link='/privacy-policy' longerButton={lang === 'fr'} className='xl:!text-[16px] !px-8' />
        </div>
      </div>
      <div className='mb-24 text-white text-[16px] md:text-[20px] w-full text-center'>
        {t('rights')}
      </div>
    </section>
  )
}
