import styles from './styles.module.css'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useState } from 'react'

export default function OafTokenMobile () {
  const t = useTranslations('token')
  const [extendedQuestion, setExtendedQuestion] = useState(0)
  const [closedQuestion, setClosedQuestion] = useState(-1)

  const handleDisplayAnswer = (index: number) => {
    setClosedQuestion(extendedQuestion)
    index === extendedQuestion
      ? setExtendedQuestion(-1)
      : setExtendedQuestion(index)
  }

  const content = [
    {
      title: t('0.title'),
      description: t('0.description'),
      amount: t('0.amount'),
      percentage: t('0.percentage'),
      with: t('0.with')
    },
    {
      title: t('1.title'),
      items: [
        { title: t('1.1.title'), description: t('1.1.description') }, { title: t('1.2.title'), description: t('1.2.description') }, { title: t('1.3.title'), description: t('1.3.description') }, { title: t('1.4.title'), description: t('1.4.description') }, { title: t('1.5.title'), description: t('1.5.description') }
      ]
    }
  ]

  return (
    <section className='mt-40'>
      <div className='mb-10 ml-9'>
        <div className='flex items-end w-fit relative'>
          <h1>$OAF</h1>
          <div className='absolute' style={{ left: 'calc(100% + 20px)', bottom: '30px' }}>
            <div className='relative w-fit'>
              <Image src='/img/team-title.webp' width={187} height={140} alt='Who are the happy marmots' />
              <p className='font-londrina text-secondary text-[28px] leading-[32px] pl-7 pr-16 -mt-[50px] uppercase' style={{ transform: 'rotate(-4.65deg)' }}>{t('token')}</p>
            </div>
          </div>
        </div>
      </div>
      <p className='text-[#fff] text-[16px] leading-[32px] mt-15'>{t('description')}</p>
      <div>
        {content.map(({ title }, index) => {
          return (
            <div
              key={title}
              className='py-8 cursor-pointer'
            >
              <div onClick={() => handleDisplayAnswer(index)} className='flex justify-between items-center'>
                <div className='flex items-center'>
                  <Image src={extendedQuestion === index ? '/img/mark.svg' : '/img/mark-empty.svg'} width={32} height={42} alt='Details about our mission and vision' />
                  <h1 className={`ml-4 text-orange ${extendedQuestion !== index ? 'font-londrina-outline text-[40px] leading-[46px]' : 'font-londrina text-[40px] leading-[46px]'}`}>
                    {t(`${index}.title`)}
                  </h1>
                </div>
                <Image
                  src='/img/arrow.svg'
                  alt='Arrow to display the answer'
                  width={24}
                  height={13}
                  className={`${styles.arrow} ${
                    extendedQuestion === index && styles.rotate
                  } ${closedQuestion === index && styles.rotateBack}`}
                  style={{ filter: extendedQuestion !== index ? 'brightness(0) saturate(100%) invert(88%) sepia(35%) saturate(145%) hue-rotate(339deg) brightness(106%) contrast(93%)' : 'unset' }}
                />
              </div>
              {
                extendedQuestion === 0 && index === 0 && <div className={`text-[#fff] font-medium text-[16px] leading-[32px] mt-8 ${styles.displayAnswer}`}><span className={styles.highlight}>{t(`${extendedQuestion}.amount`)}</span>{t(`${extendedQuestion}.with`)}<span className={styles.highlight}>{t(`${extendedQuestion}.percentage`)}</span>{t(`${extendedQuestion}.description`)}</div>
              }
              {
                extendedQuestion === 1 && index === 1 && <ul style={{ listStyleImage: "url('/img/list-item.svg')" }} className={'ml-5 mt-5 text-[#fff] font-medium text-[16px]'}>
                  {
                    content[extendedQuestion].items?.map(item => {
                      return (
                        <li key={item.title} className='text-[16px] leading-[48px] pl-1'>
                          <span className={styles.highlight}>{item.title}</span>{item.description}
                        </li>
                      )
                    })
                  }
                </ul>
              }
            </div>
          )
        })}
      </div>
    </section>
  )
}
