import { useRef, useState } from 'react'
import config from 'config/all-config.json'
import { useTranslations } from 'next-intl'
import styles from '../EcosystemElements/styles.module.css'
import Image from 'next/image'

export default function EcosystemElementsMobile () {
  const t = useTranslations('ecosystem.cards')
  const [selectedItem, setSelectedItem] = useState(0)
  const ref = useRef<any>()

  const handleSelect = (index: number) => {
    ref.current.classList.add(styles.disappear)
    setTimeout(() => {
      setSelectedItem(index)
    }, 200)
    setTimeout(() => {
      ref.current.classList.remove(styles.disappear)
    }, 400)
  }

  return (
    <section className='flex flex-col items-center'>
      <div className='grid grid-cols-3 gap-[14px] w-fit'>
        {
          config.ecosystem.cards.map(({ img, hover }, index) => {
            return (
              <div
                key={index}
                className='relative'
                onClick={() => handleSelect(index)}
              >
                <Image
                  src={`/img/ecosystem/${img}`}
                  width={100}
                  height={100}
                  alt={t(`${index}.title`)}
                  className='z-10 relative min-w-[100px] min-h-[100px] w-full h-full md:w-[195px] md:h-[195px]'
                />
                <Image
                  src={`/img/ecosystem/${hover}`}
                  width={100}
                  height={100}
                  alt={t(`${index}.title`)}
                  className={`absolute top-0 left-0 min-w-[100px] min-h-[100px] w-full h-full md:w-[195px] md:h-[195px] ${
                    selectedItem === index ? 'z-20 ' : 'z-0'
                  }`}
                />
              </div>
            )
          })
        }
      </div>
      <div ref={ref} className='text-start mt-[30px] md:mt-[64px] md:max-w-[613px]'>
        <h2 className='mb-2'>{t(`${selectedItem}.title`)}</h2>
        <p className='text-[16px] leading-[32px] font-medium'>{t(`${selectedItem}.description`)}</p>
      </div>
    </section>
  )
}
