import { DefaultLayout } from '@components/layouts/default.layout'
import Contact from '@components/molecules/Contact'
import Faq from '@components/molecules/Faq'
import Footer from '@components/molecules/Footer'
import Header from '@components/molecules/Header'
import HikeWithUs from '@components/molecules/HikeWithUs'
import HikeWithUsMobile from '@components/molecules/HikeWithUsMobile'
import Nft from '@components/molecules/Nft'
import NftMobile from '@components/molecules/NftMobile'
import WhoTheyAre from '@components/molecules/WhoTheyAre'
import WhoTheyAreMobile from '@components/molecules/WhoTheyAreMobile'
import Whitepaper from '@components/organisms/Whitepaper'
import { useIsMobile } from '@hooks/usIsMobile'
import Head from 'next/head'
import { ReactElement } from 'react'
import Ecosystem from '@components/organisms/Ecosystem'
import Team from '@components/organisms/Team'
import TeamMobile from '@components/organisms/TeamMobile'
import NftTablet from '@components/molecules/NftTablet'
import { useIsTablet } from '@hooks/useIsTablet'
import MarmotSwiper from '@components/molecules/MarmotSwiper'
import Roadmap from '@components/organisms/Roadmap'
import Token from '@components/organisms/Token'

export default function Home () {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  return (
    <>
      <Head>
        <title>Happy Marmots</title>
        <meta
          name='description'
          content='Join us on a journey where you&aposll earn rewards, unlock unique experiences and create unforgettable memories in the great outdoors'
        />
        <link rel='icon' href='/favicon-new.png' />
      </Head>

      <div className='max-width-container'>
        <Header />
        <MarmotSwiper />
        {
          isMobile
            ? (
              <>
                <WhoTheyAreMobile />
                <NftMobile />
              </>
            )
            : (
              isTablet
                ? (
                  <>
                    <WhoTheyAre />
                    <div className='flex justify-center'>
                      <NftTablet />
                    </div>
                  </>
                )
                : (
                  <>
                    <WhoTheyAre />
                    <div className='flex justify-center'>
                      <Nft />
                    </div>
                  </>
                ))
        }
        <Token />
        <Ecosystem />
        {
          isMobile || isTablet ? <TeamMobile /> : <Team />
        }
        <Roadmap />
        <Whitepaper />
        <div className='flex justify-center'>
          <Faq />
        </div>
        {isMobile ? <HikeWithUsMobile /> : <HikeWithUs />}
        <Contact />
        <Footer />
      </div>
    </>
  )
}

export async function getStaticProps (context: any) {
  return {
    props: {
      // You can get the messages from anywhere you like. The recommended
      // pattern is to put them in JSON files separated by locale and read
      // the desired one based on the `locale` received from Next.js.
      messages: (await import(`../messages/${context.locale}.json`)).default
    }
  }
}

Home.getLayout = function getLayout (page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>
}
