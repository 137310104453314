import Steps from '@components/molecules/Steps'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import styles from './styles.module.css'

export default function Roadmap () {
  const t = useTranslations('roadmap')
  const lang = useLocale()

  return (
    <section className='mt-[154px] xl:mt-[250px] flex flex-col items-center w-full'>
      <div className='mb-[76px] md:mb-[137px] pl-1 md:pl-24 w-full'>
        <h1>{t('steps')}</h1>
        <div className='flex items-end w-fit relative'>
          <h1>{t('the')}</h1>
          <div
            className={`absolute bottom-[30%] md:bottom-[40%] ${styles.alignLeft}`}
          >
            <div className='relative w-fit'>
              <Image
                src={'/img/roadmap/roadmap-title.svg'}
                width={lang === 'en' ? 270 : 360}
                height={250}
                className={`${lang === 'en' ? 'w-[360px]' : '!w-[230px] !max-w-[230px] md:!w-[400px] md:!max-w-[380px]'}`}
                alt='More than just an Nft'
              />
              <p
                className={`font-londrina text-secondary text-[44px] md:text-[80px] whitespace-nowrap pr-16 md:pr-24 -mt-[60px] ${lang === 'en' ? 'pl-8 md:-mt-[82px] md:pl-12' : 'pl-6 -mt-[75px] md:-mt-[110px] md:pl-8'} uppercase`}
                style={{ transform: 'rotate(-4.65deg)' }}
              >
                {t('way')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Steps />
    </section>
  )
}
