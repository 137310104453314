import { useIsTablet } from '@hooks/useIsTablet'
import config from 'config/all-config.json'
import { useTranslations } from 'next-intl'

export default function TeamCardDescription ({ selectedItem, isMobile }: { selectedItem: number, isMobile?: boolean }) {
  const t = useTranslations('team.cards')
  const isTablet = useIsTablet()

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        {
          isTablet
            ? <h2 className={`mb-2 ${isMobile ? '!text-[#003D4D]' : 'text-white'}`}>{t(`${selectedItem}.title`)}</h2>
            : <h3 className={`mb-2 ${isMobile ? '!text-[#003D4D]' : 'text-white'}`}>{t(`${selectedItem}.title`)}</h3>
        }
        <p className={`mb-8 text-[16px] md:text-[24px] md:leading-[48px] font-light ${isMobile ? '!text-[#003D4D]' : 'text-white'}`}>{t(`${selectedItem}.subtitle`)}</p>
        <p className={`mb-4 text-[16px] leading-[32px] overflow-scroll max-h-[180px] font-medium ${isMobile ? '!text-[#003D4D]' : 'text-white'}`}>{t(`${selectedItem}.description`)}</p>
        <a href={'https://' + config.team.cards[selectedItem].link} rel='noopener noreferrer' target='_blank' className={`text-[16px] leading-[32px] font-bold ${isMobile ? '!text-[#003D4D]' : 'text-white'}`}>{config.team.cards[selectedItem].link}</a>
      </div>
      <p className={`text-[16px] leading-[32px] font-bold ${isMobile ? '!text-[#003D4D]' : 'text-white'}`}>{t(`${selectedItem}.conclusion`)}</p>
    </div>
  )
}
