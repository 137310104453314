import { useIsMobile } from '@hooks/usIsMobile'

export default function TeamCard ({ color }: { color: string }) {
  const isMobile = useIsMobile()
  return (
    <svg width={isMobile ? '328' : '462'} height={isMobile ? '466' : '656'} style={{ width: '100%', height: '100%' }} viewBox="0 0 328 466" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M321.351 459.443C308.87 472.86 304.987 461.53 287.331 461.53C269.674 461.53 269.675 459.358 252.018 459.358C214.952 463.014 183.289 462.701 146.074 460.884C119.664 461.906 101.823 461.644 75.4278 460.43C57.7645 460.43 57.7645 458.854 40.1012 458.854C22.4379 458.854 17.9226 473.549 5.4277 460.125C-7.06015 446.714 5.91046 441.859 5.91046 422.89C7.06056 382.943 6.01695 348.988 5.26441 309.041C4.73196 269.009 7.66401 235.033 3.56766 195.186C3.56766 176.21 6.31513 176.21 6.31513 157.241C6.31513 138.265 3.61735 138.265 3.61735 119.282C5.74007 90.9638 6.97537 71.8317 6.24413 43.3572C6.24413 24.3742 -6.74777 19.8592 5.74007 6.42767C18.2208 -6.98965 22.4308 6.1153 40.087 6.1153C57.7432 6.1153 57.7432 5.66806 75.3994 5.66806C146.912 1.366 215.897 9.71455 287.309 4.61029C304.973 4.61029 309.112 -6.92576 321.607 6.49866C334.094 19.9089 324.439 24.36 324.439 43.3359C323.119 71.6188 321.429 90.8857 320.804 119.24C323.254 183.628 321.223 244.589 323.701 308.992C323.786 337.353 323.687 356.534 323.573 384.91C323.573 403.893 318.568 404.389 321.983 423.01C325.49 442.15 333.825 446.026 321.337 459.45L321.351 459.443Z" fill={color}/>
    </svg>
  )
}
