import { useIsMobile } from '@hooks/usIsMobile'
import styles from './styles.module.css'
import config from 'config/all-config.json'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useState } from 'react'
import DOMPurify from 'isomorphic-dompurify'
import parse from 'html-react-parser'

export default function Faq () {
  const t = useTranslations('faq')
  const [extendedQuestion, setExtendedQuestion] = useState(-1)
  const [closedQuestion, setClosedQuestion] = useState(-1)
  const isMobile = useIsMobile()

  const handleDisplayAnswer = (index: number) => {
    setClosedQuestion(extendedQuestion)
    index === extendedQuestion
      ? setExtendedQuestion(-1)
      : setExtendedQuestion(index)
  }

  return (
    <section className='flex flex-col items-start mt-[152px] md:mt-[220px] max-w-[856px]'>
      <div className='mb-20 md:mb-[120px] xl:mb-[136px] md:ml-12'>
        <h1>{t('frequently')}</h1>
        <div className='flex items-end w-fit relative'>
          <h1>{t('asked')}</h1>
          <div className={`absolute bottom-[20%] md:bottom-[30%] ${styles.alignLeft}`}>
            <div className='relative w-fit'>
              <Image src={isMobile ? '/img/faq-title-mobile.webp' : '/img/faq-title-desktop.webp'} width={550} height={200} alt='Who are the happy marmots' />
              <p className='font-londrina text-secondary text-[25px] md:text-[60px] whitespace-nowrap pl-4 pr-10 md:pl-12 md:pr-24 -mt-[48px] md:-mt-[80px] uppercase' style={{ transform: 'rotate(-2deg)' }}>{t('questions')}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        {config.faq.map(({ question }, index) => {
          return (
            <div
              key={question}
              className='border border-solid border-transparent border-b-[#185873] py-8'
            >
              <div onClick={() => handleDisplayAnswer(index)} className='flex justify-between items-center cursor-pointer'>
                <p className={`font-bold text-[24px] leading-[48px] ${extendedQuestion === index ? 'text-[#fff]' : 'text-white'}`}>
                  {t(`${index}.question`)}
                </p>
                <Image
                  src='/img/arrow.svg'
                  alt='Arrow to display the answer'
                  width={24}
                  height={13}
                  className={`${styles.arrow} ${
                    extendedQuestion === index && styles.rotate
                  } ${closedQuestion === index && styles.rotateBack}`}
                />
              </div>
              {
                extendedQuestion === index && <p className={`text-[#fff] font-medium text-[16px] leading-[32px] mt-8 ${styles.displayAnswer}`}>
                  {parse(DOMPurify.sanitize(t.raw(`${index}.answer`)))}
                </p>
              }
            </div>
          )
        })}
      </div>
    </section>
  )
}
