import { useRouter } from 'next/router'
import Link from 'next/link'
import { useLocale } from 'next-intl'
import Image from 'next/image'

export default function LanguageSwitch () {
  const lang = useLocale()
  const router = useRouter()

  return (
    <>
      <div className='flex items-center'>
        <Link href={router.pathname} locale="en">
          <p className={`font-londrina text-[16px] ${lang === 'en' ? 'text-orange' : 'text-white'}`}>EN</p>
        </Link>
        <div className='mx-[9px]'>
          <Image src='/img/separator.svg' width={3} height={20} alt='Choose language' className='!h-[20px]' />
        </div>
        <Link href={router.pathname} locale="fr">
          <p className={`font-londrina text-[16px] ${lang === 'fr' ? 'text-orange' : 'text-white'}`}>FR</p>
        </Link>
      </div>
    </>
  )
}
