import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useRef, useState } from 'react'
import config from 'config/all-config.json'
import styles from './styles.module.css'
import RoadmapDetailed from '@components/atoms/RoadmapDetailed'

export default function Steps () {
  const t = useTranslations('roadmap.cards')
  const roadmapScrollContainer = useRef<any>()
  const [displayLeft, setDisplayLeft] = useState(false)
  const [displayRight, setDisplayRight] = useState(true)
  const [selectedItem, setSelectedItem] = useState(0)
  const ref = useRef<any>()

  const handleSelect = (index: number) => {
    ref.current.classList.add(styles.disappear)
    setTimeout(() => {
      setSelectedItem(index)
    }, 200)
    setTimeout(() => {
      ref.current.classList.remove(styles.disappear)
    }, 400)
  }

  const stepsPositions = [
    {
      top: 25,
      left: 54,
      rotate: 0
    },
    {
      top: 57,
      left: 235,
      rotate: 8.5
    },
    {
      top: 28,
      left: 471,
      rotate: -7
    },
    {
      top: 63,
      left: 712,
      rotate: -1.5
    },
    {
      top: 36,
      left: 804,
      rotate: -1.5
    },
    {
      top: 25,
      left: 934,
      rotate: -1.5
    },
    {
      top: 45,
      left: 1015,
      rotate: -1.5
    },
    {
      top: 66,
      left: 1091,
      rotate: -5.8
    },
    {
      top: 26,
      left: 1268,
      rotate: 5.8
    }
  ]

  const handleSlide = (direction: string) => {
    if (!roadmapScrollContainer.current) return
    direction === 'left' ? smoothScroll('left') : smoothScroll('right')
  }

  const smoothScroll = (direction: string) => {
    let times = 0

    const interval = setInterval(() => {
      times++
      if (times === 15) clearInterval(interval)

      direction === 'left' ? roadmapScrollContainer.current.scrollLeft -= 8 : roadmapScrollContainer.current.scrollLeft += 8
      roadmapScrollContainer.current.scrollLeft > 0 ? setDisplayLeft(true) : setDisplayLeft(false)
      roadmapScrollContainer.current.scrollLeft > 447 ? setDisplayRight(false) : setDisplayRight(true)
    }, 25)
    return () => clearInterval(interval)
  }

  const handleScrollContainer = (e: any) => {
    e.target.scrollLeft > 0 ? setDisplayLeft(true) : setDisplayLeft(false)
    e.target.scrollLeft > 447 ? setDisplayRight(false) : setDisplayRight(true)
  }

  return (
    <>
      <div className='w-full relative'>
        <div
          ref={roadmapScrollContainer}
          onScroll={() => handleScrollContainer(event)}
          className='w-full h-[400px] relative overflow-scroll no-scrollbar'
        >
          <Image src='/img/roadmap/roadmap.svg' fill alt='roadmap' className='!w-[1500px] !max-w-[1500px] min-h-[400px]' />
          {
            config.roadmap.cards.map(({ image }, index) => {
              return (
                <div key={index} onClick={() => handleSelect(index)}>
                  {
                    image === 'year'
                      ? <button
                        className='absolute z-20'
                        style={{ left: `${stepsPositions[index].left}px`, top: `${stepsPositions[index].top}%`, transform: `rotate(${stepsPositions[index].rotate}deg)` }}
                      >
                        <div className={`relative ${selectedItem === index ? styles.selectedItem : ''}`}>
                          <svg className={styles.hovered} width="120" height="74" viewBox="0 0 120 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M108.357 67.8347C101.463 67.8347 101.463 68.5416 94.5642 68.5416C87.665 68.5416 87.665 70.4231 80.7711 70.4231C73.8719 70.4231 73.8719 72 66.978 72C60.0788 72 60.0788 67.4541 53.1849 67.4541C46.2857 67.4541 46.2857 67.5628 39.3918 67.5628C32.498 67.5628 32.4926 71.9021 25.5933 71.9021C18.694 71.9021 18.694 68.3241 11.7948 68.3241C8.20934 68.3241 7.12827 67.5302 7.12827 63.9413C7.12827 57.416 2 57.416 2 50.8907C2 44.3654 8.2528 44.3654 8.2528 37.8401C8.2528 31.3148 6.44378 31.3148 6.44378 24.7841C6.44378 18.2588 5.30296 18.2588 5.30296 11.7281C5.30296 8.13921 8.2039 3.54432 11.7893 3.54432C18.6832 3.54432 18.6832 2 25.5824 2C32.4817 2 32.4817 4.29473 39.3755 4.29473C46.2694 4.29473 46.2748 4.13159 53.1686 4.13159C60.0625 4.13159 60.0679 4.37629 66.9617 4.37629C73.8556 4.37629 73.861 7.74769 80.7548 7.74769C87.6487 7.74769 87.6541 2.26645 94.5534 2.26645C101.453 2.26645 101.453 5.39859 108.352 5.39859C111.937 5.39859 118 8.14464 118 11.7335C118 18.2588 113.453 18.2588 113.453 24.7841C113.453 31.3094 112.198 31.3094 112.198 37.8347C112.198 44.36 113.589 44.36 113.589 50.8907C113.589 57.416 115.425 57.416 115.425 63.9467C115.425 67.5356 111.932 67.8456 108.346 67.8456L108.357 67.8347Z" stroke="#F6F0DD" strokeWidth="4" strokeMiterlimit="10"/>
                          </svg>
                          <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center font-londrina text-white text-[32px]'>
                            {t(`${index}.title`)}
                          </div>
                        </div>
                      </button>
                      : <button
                        className='absolute z-20'
                        style={{ left: `${stepsPositions[index].left}px`, top: `${stepsPositions[index].top}%`, transform: `rotate(${stepsPositions[index].rotate}deg)` }}
                      >
                        <div className={`relative ${selectedItem === index ? styles.selectedItem : ''}`}>
                          <svg className={styles.hovered} width="79" height="49" viewBox="0 0 79 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M70.2382 45.6692C65.7551 45.5489 65.7428 46.0086 61.2562 45.8882C56.7695 45.7677 56.7367 46.9913 52.2536 46.8709C47.767 46.7505 47.7394 47.776 43.2563 47.6557C38.7697 47.5352 38.8491 44.579 34.366 44.4586C29.8793 44.3382 29.8774 44.4089 25.3943 44.2886C20.9112 44.1682 20.832 46.99 16.3453 46.8696C11.8587 46.7492 11.9211 44.4223 7.43451 44.3019C5.10288 44.2393 4.41372 43.7042 4.47636 41.3703C4.59027 37.1269 1.25532 37.0373 1.36923 32.7939C1.48313 28.5505 5.54936 28.6596 5.66327 24.4162C5.77717 20.1728 4.60075 20.1412 4.71475 15.8942C4.82866 11.6508 4.08678 11.6309 4.20077 7.3839C4.26342 5.05002 6.23013 2.11257 8.56177 2.17516C13.0449 2.2955 13.0718 1.29122 17.5585 1.41165C22.0451 1.53208 22.005 3.02436 26.4881 3.1447C30.9712 3.26503 30.9776 3.15904 35.4607 3.27938C39.9438 3.39972 39.9431 3.55894 44.4262 3.67928C48.9093 3.79962 48.8539 5.99215 53.337 6.11249C57.8201 6.23282 57.9194 2.66844 62.406 2.78888C66.8926 2.90931 66.8379 4.94615 71.3246 5.06659C73.6562 5.12917 77.5509 7.02078 77.4882 9.35466C77.3743 13.5981 74.4174 13.5187 74.3035 17.7622C74.1896 22.0056 73.3735 21.9837 73.2596 26.2271C73.1457 30.4705 74.0501 30.4948 73.9361 34.7418C73.8222 38.9852 75.0162 39.0173 74.9022 43.2642C74.8396 45.5981 72.5626 45.7387 70.231 45.6761L70.2382 45.6692Z" stroke="#F6F0DD" strokeWidth="2" strokeMiterlimit="10"/>
                          </svg>
                          <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center font-londrina text-white text-[24px]'>
                            {t(`${index}.title`)}
                          </div>
                        </div>
                      </button>
                  }
                </div>
              )
            })
          }
        </div>
        {
          displayLeft && <div className='absolute top-0 left-0 h-full w-[150px] xl:w-[300px] z-10' style={{ background: 'linear-gradient(90deg, #0A1F28 0%, rgba(10, 31, 40, 0) 100%)' }}></div>
        }
        {
          displayRight && <div className='absolute top-0 right-0 h-full w-[150px] xl:w-[300px] z-10' style={{ background: 'linear-gradient(270deg, #0A1F28 0%, rgba(10, 31, 40, 0) 100%)' }}></div>
        }
      </div>
      <div className='mt-[50px] flex justify-between w-full'>
        <div>
          {
            displayLeft &&
              <button onClick={() => handleSlide('left')}>
                <Image src='/img/arrow-left.svg' width={100} height={32} className='!w-[62px] !h-[20px] md:!w-[100px] md:!h-[32px]' alt='Choose another team card' />
              </button>
          }
        </div>

        <div>
          {
            displayRight &&
              <button onClick={() => handleSlide('right')}>
                <Image src='/img/arrow-right.svg' width={100} height={32} className='!w-[62px] !h-[20px] md:!w-[100px] md:!h-[32px]' alt='Choose another team card' />
              </button>
          }
        </div>
      </div>

      <div ref={ref} className='w-full'>
        {
          selectedItem >= 0 &&
            <RoadmapDetailed title={t(`${selectedItem}.title`)} description={t(`${selectedItem}.description`)} additionalInfo={t(`${selectedItem}.additionalInformation`)} />
        }
      </div>
    </>
  )
}
