import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'

export default function WhitepaperCardMobile () {
  const t = useTranslations('whitepaper')
  const lang = useLocale()

  return (
    <section className='flex flex-col items-center'>
      <div className='relative w-full'>
        <div className='relative z-10 w-full'>
          <div className='flex flex-col justify-center py-10 ml-6'>
            <div className='text-start max-w-[68%]'>
              <p className='text-[16px] leading-[32px] font-medium'>{t('dive')}</p>
            </div>
            <div className='flex items-center'>
              <div className='relative w-fit h-fit pt-4 pb-4 mb-6 mt-6'>
                <a
                  href={'/WHITEPAPER.pdf'}
                  target='_blank'
                  className='text-orange text-[24px] font-londrina font-normal z-10 relative !leading-[48px] !px-6 !block !py-1 uppercase'
                >
                  {t('download')}
                </a>
                <Image src='/img/download-border.svg' fill alt='Whitepaper' className='!w-full !h-full !max-h-full' />
              </div>
              <div className={`${lang === 'en' ? 'ml-20' : 'ml-16'} -mb-20`}>
                <Image src='/img/whitepaper-marmot.webp' width={120} height={170} alt='Download our whitepaper' />
              </div>
            </div>
          </div>
        </div>
        <Image src='/img/whitepaper-card.svg' fill alt='Hike with us' className='!w-full !h-[327px] -ml-6' />
      </div>
    </section>
  )
}
