import styles from './styles.module.css'
import config from 'config/all-config.json'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useState } from 'react'

export default function WhoTheyAreMobile () {
  const t = useTranslations('who')
  const [extendedQuestion, setExtendedQuestion] = useState(0)
  const [closedQuestion, setClosedQuestion] = useState(-1)

  const handleDisplayAnswer = (index: number) => {
    setClosedQuestion(extendedQuestion)
    index === extendedQuestion
      ? setExtendedQuestion(-1)
      : setExtendedQuestion(index)
  }

  return (
    <section className='mb-40'>
      <div className='mb-20 ml-9'>
        <h1>{t('who')}</h1>
        <div className='flex items-end w-fit relative'>
          <h1>{t('the')}</h1>
          <div className='absolute bottom-[15%]' style={{ left: 'calc(100% + 20px)' }}>
            <div className='relative w-fit'>
              <Image src='/img/who-title-mobile.svg' width={187} height={140} alt='Who are the happy marmots' />
              <p className='font-londrina text-secondary text-[28px] leading-[32px] pl-7 pr-16 -mt-[80px] uppercase' style={{ transform: 'rotate(-4.65deg)' }}>{t('happy')}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        {config.who.map(({ title }, index) => {
          return (
            <div
              key={title}
              className='py-8 cursor-pointer'
            >
              <div onClick={() => handleDisplayAnswer(index)} className='flex justify-between items-center'>
                <div className='flex items-center'>
                  <Image src={extendedQuestion === index ? '/img/mark.svg' : '/img/mark-empty.svg'} width={32} height={42} alt='Details about our mission and vision' />
                  <h1 className={`ml-4 md:text-[64px] text-orange ${extendedQuestion !== index ? 'font-londrina-outline' : 'font-londrina'}`}>
                    {t(`${index}.title`)}
                  </h1>
                </div>
                <Image
                  src='/img/arrow.svg'
                  alt='Arrow to display the answer'
                  width={24}
                  height={13}
                  className={`${styles.arrow} ${
                    extendedQuestion === index && styles.rotate
                  } ${closedQuestion === index && styles.rotateBack}`}
                  style={{ filter: extendedQuestion !== index ? 'brightness(0) saturate(100%) invert(88%) sepia(35%) saturate(145%) hue-rotate(339deg) brightness(106%) contrast(93%)' : 'unset' }}
                />
              </div>
              {
                extendedQuestion === index && <p className={`text-[#fff] font-medium text-[16px] leading-[32px] mt-8 ${styles.displayAnswer}`}>
                  {t(`${index}.description`)}
                </p>
              }
            </div>
          )
        })}
      </div>
    </section>
  )
}
