import TeamSlider from '@components/molecules/TeamSlider'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'

export default function Team () {
  const t = useTranslations('team')
  const lang = useLocale()

  return (
    <section className='mt-[250px] flex-flex-col items-center'>
      <div className='mb-[137px] pl-24 w-full'>
        <h1>{t('all')}</h1>
        <div className='flex items-end w-fit relative'>
          <h1>{t('the')}</h1>
          <div
            className='absolute bottom-[40%]'
            style={{ left: 'calc(100% + 48px)' }}
          >
            <div className='relative w-fit'>
              <Image
                src='/img/team-title.webp'
                width={330}
                height={250}
                alt='More than just an Nft'
                style={{ width: 'calc(100% + 60px)' }}
              />
              <p
                className={`font-londrina text-secondary text-[80px] whitespace-nowrap pl-10 ${lang === 'en' ? '-mt-[95px] pr-32' : '-mt-[110px] pr-36'} uppercase`}
                style={{ transform: 'rotate(-4.65deg)' }}
              >
                {t('team')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center'>
        <TeamSlider />
      </div>
    </section>
  )
}
