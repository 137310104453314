import { useEffect, useState } from 'react'

export const useIsTablet = (state?: boolean) => {
  const [isTablet, setIsTablet] = useState(true)

  useEffect(() => {
    const checkIfMobile = () => {
      setIsTablet(window.innerWidth >= 755 && window.innerWidth <= 1100)
    }

    checkIfMobile()

    window.addEventListener('resize', checkIfMobile)

    return () => {
      window.removeEventListener('resize', checkIfMobile)
    }
  }, [])

  return isTablet
}
